export type fileType = {
	name: string
	size: number
	type: string
}

export type deliveryManagementAdminSingleRuleType = {
	salesOrganizationId: string
	cityName: string
	state: string
	cutoffTime1: string
	cutoffTime2: string
	saturdayDelivery: string
	deliveryLeadTimeDays: string
	isCityActive: string
}

export type deliveryManagementAdminRulesType =
	deliveryManagementAdminSingleRuleType[]

export enum DeliveryManagementeRulesFileHeaders {
	CityName = 'Cidade',
	stateName = 'Estado',
	CutOffTime1 = 'Primeiro Horário de Corte',
	CutOffTime2 = 'Segundo Horário de Corte',
	SaturdayDelivery = 'Entrega aos sabados?',
	DeliveryLeadTimeDays = 'Quantos dias para entrega?',
	StateName = 'Estado',
	SalesOrganizationID = 'Cód. Unidade',
	IsCityActive = 'Cidade atendida?'
}
